<template>
  <div>
    <Drawer :title="getTitle"
            width="80vw"
            @on-close="$emit('close')"
            v-model="isOpen"
    >
      <p>Selected Property : <strong>{{ property.displayName }}</strong></p>

      <Row style="width:100%"
           v-if="!(this.sldData.day_date && this.$route.path.includes('notifications'))"
      >
        <Col span="22"
             offset="1"
             v-if="availableRangeValues[0] != availableRangeValues[availableRangeValues.length - 1]"
        >
          <vue-slider @drag-end="()=>selectedRangeValues = selectingRangeValues"
                      v-model="selectingRangeValues"
                      :data="availableRangeValues"
                      :marks="true"
                      :enable-cross="false"
                      :tooltip-placement="['top', 'top']"
                      :min-range="0"
                      :max-range="6"
          />
        </Col>
      </Row>
      <FilterTable
        v-if="isOpen"
        :show-columns-selector="false"
        name="Sld list"
        :trigger="triggerTable"
        :fetch-items="sldData.context === 'tracker' ? this.getTrackersSld : this.getRequestsSld"
        :export-csv="sldData.context === 'tracker' ? exportPgTrackers : exportPgRequests"
        :fetch-items-params="getParams"
        namespace="cookie-sld"
        :columns="getColumns"
        :limit="10"
      />
    </Drawer>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import tableColumn from '@/mixins/tableColumn'
import FilterTable from '@/components/ui/FilterTable'
import moment from 'moment'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  components: {FilterTable, VueSlider},
  mixins: [
    tableColumn
  ],
  props: {
    magicDaterange: {
      type: [String, Boolean],
      note: 'Force maximum day range for data',
      default: false,
      require: true
    },
    forcePropertyId: {
      type: Number,
      default: 0,
      note : 'propertyId to use'
    },
    sldData: {
      type: Object,
      default: () => {}
    },
    sldOpen: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    // dateSliderRange: {
    //   handler: function (val) {
    //     console.log('ehey')
    //     // if (val[0] !== this.oldDateRange[0] && val[1] !== this.oldDateRange[1]) {
    //     //   this.triggerTable = true
    //     //   this.triggerTable = false
    //     // }
    //   }
    // },
    sldOpen: {
      immediate: true,
      handler: function (val, old) {
        this.isOpen = val
        this.trigger = false
        if (val === true) {
          const { daterange } = this
          this.availableRangeValues = []
          const availableRangeStartDate = moment(daterange[0])
          const availableRangeEndDate = moment(daterange[1])

          let availableRangeIterator = moment(daterange[0])
          while (availableRangeIterator.isBefore(availableRangeEndDate)){
            this.availableRangeValues.push(availableRangeIterator.format('YYYY-MM-DD'))
            availableRangeIterator.add(1, 'day')
          }
          this.availableRangeValues.push(availableRangeEndDate.format('YYYY-MM-DD'))

          this.selectingRangeValues = [availableRangeStartDate.format('YYYY-MM-DD'), availableRangeStartDate.format('YYYY-MM-DD')]
          this.selectedRangeValues = [availableRangeStartDate.format('YYYY-MM-DD'), availableRangeStartDate.format('YYYY-MM-DD')]
          this.trigger = true
        }
      }
    },
    daterangeList: {
      // immediate: true,
      handler (val, old) {
        if (val.length > 0) this.forcedDate = val[0].value
      }
    }
  },
  data () {
    return {
      availableRangeValues:[],
      selectingRangeValues:[],
      selectedRangeValues:[],
      loadDateRange: false,
      isOpen: false,
      triggerTable: false,
      trigger: true,
      moment: moment,
      marks: {
      }
    }
  },
  computed: {
    ...mapGetters({
      userHasRole: 'user/hasRole',
      property: 'scope/selectedProperty',
      showCategoryHelper: 'vendor/showCategoryHelper',
      vendorVigilanceToLetter: 'vendor/vendorVigilanceToLetter',
      complianceColor: 'vendor/riskColor',
      daterange: 'scope/selectedDaterange',
      selectedProperty: 'scope/selectedProperty'
    }),
    getTitle () {
      const title = (this.sldData.context === 'tracker' ? 'Trackers detail' : 'Requests detail') + (this.magicDaterange ? ` for the ${this.magicDaterange}` : '')
      const weeklyOrTitle = (this.sldData.day_date && this.$route.path.includes('notifications')) ? 'Trackers for the ' + moment(this.sldData.day_date).format('LL') : title
      return weeklyOrTitle
    },
    getParams () {
      let params = {}
      let dates = []
      // if (this.forcedDate && this.trigger) {
      if (this.selectedRangeValues && this.trigger) {
        if (this.sldData.day_date && this.$route.path.includes('notifications')) {

          dates.push(moment(this.sldData.day_date).format('YYYY-MM-DD'))
          dates.push(moment(this.sldData.day_date).format('YYYY-MM-DD'))
        } else {
          dates.push(this.selectedRangeValues[0])
          dates.push(this.selectedRangeValues[1])
          // dates.push(this.forcedDate.split('  to  ')[0])
          // dates.push(this.forcedDate.split('  to  ')[1].slice(0, 10))
        }

      } else {
        dates = this.daterange
      }
      if (this.sldData.context === 'tracker') {
        params = {
          names: [this.sldData.name],
          hash_namehosttypes: [this.sldData.hash_namehosttype],
          properties: [this.forcePropertyId || this.selectedProperty.id],
          cmpScenarioOutcomes: [this.sldData.cmp_scenario_outcome],
          period: [dates[0], dates[1]]
        }
      } else {
        params = {
          properties: [this.forcePropertyId || this.selectedProperty.id],
          period: [dates[0], dates[1]]
        }
        if (this.sldData.sld === this.sldData.host) params.slds = [this.sldData.sld]
        else params.hosts = [this.sldData.host]
      }

      return params
    },
    getColumns () {
      const t = this
      if (this.sldData.context === 'tracker') {
        return [
          {
            title: 'Date',
            sortable: 'custom',
            sortKey: 'PgTrackers.created',
            render: (h, params) => h('span', {}, moment(params.row.created).format('lll'))
          },
          //
          //
          // {
          //   title: 'Collect',
          //
          //   render: (h, params) => h('span', {
          //     class: 'chips chips--green',
          //     style: {cursor: 'pointer'}
          //     ,
          //     on: {
          //       click: () => this.openLinkInNewWindow(params.row.PgCollects.proof_of_scan_url)
          //     }
          //
          //   }, params.row.collect_id)
          // },
          this.createColumn(this.$t('global.table.name'), 'name', 'PgTrackers.name'),
          // this.createColumn(this.$t('value'), 'value', 'PgTrackers.value'),
          {
            title: 'Initiator Url',
            // sortable: 'custom',
            // sortKey: 'pgRequests.initiator_url',
            render: (h, params) => h('span', {
              class: 'chips chips--green',
              style: {cursor: 'pointer'}
              ,
              on: {
                click: () => this.copyClick(params.row.initiator_url)
              }

            }, this.truncate(params.row.initiator_url))
          },
          this.createColumn(this.$t('global.table.domain'), 'host', 'PgTrackers.host'),
          this.createColumn(this.$t('Url'), 'page_url', 'PgTrackers.value'),
          this.createColumn(this.$t('Type'), 'type', 'PgTrackers.type'),
          this.createColumn(this.$t('Initiator type'), 'initiator_type', 'PgTrackers.initiator_type'),
          // this.createColumn(this.$t('initiator url'), 'initiator_url', 'PgTrackers.initiator_url'),
          {
            title: 'Value',
            render: (h, params) => h('span', {
              class: 'chips chips--green',
              style: {cursor: 'pointer'}
              ,
              on: {
                click: () => this.copyClick(params.row.value)
              }

            }, this.truncate(params.row.value))
          },

        ]
      }
      return [
        {
          title: 'Date',
          sortable: 'custom',
          sortKey: 'pgRequests.created',
          render: (h, params) => h('span', {}, moment(params.row.created).format('lll'))
        },
        {
          title: 'Url',
          render: (h, params) => h('span', {

            class: 'chips chips--green',
            style: {cursor: 'pointer'}
            ,
            on: {
              click: () => this.copyClick(params.row.url)
            }

          }, this.truncate(params.row.url))
        },
        this.createColumn(this.$t('Domain'), 'url_host', 'PgRequests.url_host'),
        this.createColumn(this.$t('Page Url'), 'page_url', 'PgRequests.page_url'),
        this.createColumn(this.$t('Initiator type'), 'type', 'PgRequests.type'),
        {
          title: 'Initiator Url',
          render: (h, params) => h('span', {
            class: 'chips chips--green',
            style: {cursor: 'pointer'}
            ,
            on: {
              click: () => this.copyClick(params.row.initiator_url)
            }

          }, this.truncate(params.row.initiator_url))
        },

      ]

    },
  },
  methods: {
    ...mapActions({
      getTrackersSld: 'cookie/getPgTrackers',
      getRequestsSld: 'cookie/getPgRequests',
      exportPgTrackers: 'cookie/exportPgTrackers',
      exportPgRequests: 'cookie/exportPgRequests',
    }),
    /**
     * Limit the daterange to 7 day
     * auto move small or big value to follow the 7 dya ranges
     */
    // limitRange(value) {
    //   console.log(value);
    //   const grow = value[1] !== this.oldDateRange[1]
    //   const less = value[0] !== this.oldDateRange[0]
    //   if (grow) {
    //     if (value[1] - 7 > value[0]) {
    //       this.dateSliderRange = [value[1] - 7, value[1]]
    //     }
    //   }else if (less) {
    //     if (value[0] + 7 < value[1]) {
    //       this.dateSliderRange = [value[0], value[0] + 7]
    //     }
    //   } else {
    //     this.dateSliderRange = [value[0], value[1]]
    //   }
    //   this.oldDateRange = this.dateSliderRange
    //   // this.triggerTable = true
    //   // this.triggerTable = false
    // },
    format (val) {
      const last = this.selectedProperty.lastIngest
      return moment(last).subtract(90 - val, 'day').format('ddd D')
    },
    copyClick (tcString) {
      var el = document.createElement('textarea')
      el.value = tcString
      el.setAttribute('readonly', '')
      el.style = {position: 'absolute', left: '-9999px'}
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      return this.$Message.info('Copied in clipboard')
    },
    openLinkInNewWindow (link) {
      window.open(link, '_blank')
    },
    truncate(input = '') {
      if (!input) return 'N/A'
      if (input.length > 35) {
        return input.substring(0, 35) + '...'
      }
      return input
    },
  }
}
</script>
<style lang="scss">

.vue-slider-dot{position:absolute;-webkit-transition:all 0s;transition:all 0s;z-index:5}.vue-slider-dot:focus{outline:none}.vue-slider-dot-tooltip{position:absolute;visibility:hidden}.vue-slider-dot-hover:hover .vue-slider-dot-tooltip{visibility:visible}.vue-slider-dot-tooltip-show{visibility:visible}.vue-slider-dot-tooltip-top{top:-10px;left:50%;-webkit-transform:translate(-50%,-100%);transform:translate(-50%,-100%)}.vue-slider-dot-tooltip-bottom{bottom:-10px;left:50%;-webkit-transform:translate(-50%,100%);transform:translate(-50%,100%)}.vue-slider-dot-tooltip-left{left:-10px;top:50%;-webkit-transform:translate(-100%,-50%);transform:translate(-100%,-50%)}.vue-slider-dot-tooltip-right{right:-10px;top:50%;-webkit-transform:translate(100%,-50%);transform:translate(100%,-50%)}
.vue-slider-marks{position:relative;width:100%;height:100%}.vue-slider-mark{position:absolute;z-index:1}.vue-slider-ltr .vue-slider-mark,.vue-slider-rtl .vue-slider-mark{width:0;height:100%;top:50%}.vue-slider-ltr .vue-slider-mark-step,.vue-slider-rtl .vue-slider-mark-step{top:0}.vue-slider-ltr .vue-slider-mark-label,.vue-slider-rtl .vue-slider-mark-label{top:100%;margin-top:10px}.vue-slider-ltr .vue-slider-mark{-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}.vue-slider-ltr .vue-slider-mark-step{left:0}.vue-slider-ltr .vue-slider-mark-label{left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%)}.vue-slider-rtl .vue-slider-mark{-webkit-transform:translate(50%,-50%);transform:translate(50%,-50%)}.vue-slider-rtl .vue-slider-mark-step{right:0}.vue-slider-rtl .vue-slider-mark-label{right:50%;-webkit-transform:translateX(50%);transform:translateX(50%)}.vue-slider-btt .vue-slider-mark,.vue-slider-ttb .vue-slider-mark{width:100%;height:0;left:50%}.vue-slider-btt .vue-slider-mark-step,.vue-slider-ttb .vue-slider-mark-step{left:0}.vue-slider-btt .vue-slider-mark-label,.vue-slider-ttb .vue-slider-mark-label{left:100%;margin-left:10px}.vue-slider-btt .vue-slider-mark{-webkit-transform:translate(-50%,50%);transform:translate(-50%,50%)}.vue-slider-btt .vue-slider-mark-step{top:0}.vue-slider-btt .vue-slider-mark-label{top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%)}.vue-slider-ttb .vue-slider-mark{-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}.vue-slider-ttb .vue-slider-mark-step{bottom:0}.vue-slider-ttb .vue-slider-mark-label{bottom:50%;-webkit-transform:translateY(50%);transform:translateY(50%)}.vue-slider-mark-label,.vue-slider-mark-step{position:absolute}
.vue-slider{position:relative;-webkit-box-sizing:content-box;box-sizing:content-box;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;display:block;-webkit-tap-highlight-color:rgba(0,0,0,0)}.vue-slider-rail{position:relative;width:100%;height:100%;-webkit-transition-property:width,height,left,right,top,bottom;transition-property:width,height,left,right,top,bottom}.vue-slider-process{position:absolute;z-index:1}

// + custom


.vue-slider {
  margin:40px 0 ;
  .vue-slider-process{
    background-color: #1ca08d;
  }
  .vue-slider-dot{
    .vue-slider-dot-tooltip-inner{
      visibility: visible;
      border-color: #1ca08d;
      background-color: #1ca08d;
    }
  }
  .vue-slider-mark {
    overflow: hidden;
    font-size: 10px;
    &:first-child, &:last-child, &:nth-child(14n), {
      overflow: visible;
      //&:nth-last-child(2){
      //  overflow: hidden;
      //}
      & > .vue-slider-mark-step{
        display:block;
        height:300%;
        top:-100%;
      }
      & > .vue-slider-mark-label{
        font-size: 10px;
      }
    }
    &:first-child, &:last-child {
      overflow: visible;
      //margin-top: -40px;
      & > .vue-slider-mark-label{
        font-size: 10px;
      }
    }

    //&.vue-slider-mark-active {
    //  font-size: 12px;
    //  color: #1ca08d;
    //  //& ~ .vue-slider-mark-active {
    //  //  font-size: 12px;
    //  //  color: #ff0055;
    //  //}
    //}
  }
}
</style>

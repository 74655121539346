var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Drawer', {
    attrs: {
      "title": _vm.getTitle,
      "width": "80vw"
    },
    on: {
      "on-close": function onClose($event) {
        return _vm.$emit('close');
      }
    },
    model: {
      value: _vm.isOpen,
      callback: function callback($$v) {
        _vm.isOpen = $$v;
      },
      expression: "isOpen"
    }
  }, [_c('p', [_vm._v("Selected Property : "), _c('strong', [_vm._v(_vm._s(_vm.property.displayName))])]), !(this.sldData.day_date && this.$route.path.includes('notifications')) ? _c('Row', {
    staticStyle: {
      "width": "100%"
    }
  }, [_vm.availableRangeValues[0] != _vm.availableRangeValues[_vm.availableRangeValues.length - 1] ? _c('Col', {
    attrs: {
      "span": "22",
      "offset": "1"
    }
  }, [_c('vue-slider', {
    attrs: {
      "data": _vm.availableRangeValues,
      "marks": true,
      "enable-cross": false,
      "tooltip-placement": ['top', 'top'],
      "min-range": 0,
      "max-range": 6
    },
    on: {
      "drag-end": function dragEnd() {
        return _vm.selectedRangeValues = _vm.selectingRangeValues;
      }
    },
    model: {
      value: _vm.selectingRangeValues,
      callback: function callback($$v) {
        _vm.selectingRangeValues = $$v;
      },
      expression: "selectingRangeValues"
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm.isOpen ? _c('FilterTable', {
    attrs: {
      "show-columns-selector": false,
      "name": "Sld list",
      "trigger": _vm.triggerTable,
      "fetch-items": _vm.sldData.context === 'tracker' ? this.getTrackersSld : this.getRequestsSld,
      "export-csv": _vm.sldData.context === 'tracker' ? _vm.exportPgTrackers : _vm.exportPgRequests,
      "fetch-items-params": _vm.getParams,
      "namespace": "cookie-sld",
      "columns": _vm.getColumns,
      "limit": 10
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }